import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import "aos/dist/aos.css"
import AOS from "aos"
import { Link } from "gatsby"
import dnicon2 from "../images/dnicon2.png"

import don1 from "../images/client_logos/don1.jpg"
import don2 from "../images/client_logos/don2.jpg"
import don3 from "../images/client_logos/don3.jpg"
import don4 from "../images/client_logos/don4.jpg"
import don5 from "../images/client_logos/don5.jpg"
import don6 from "../images/client_logos/don6.jpg"
import don7 from "../images/client_logos/don7.jpg"
import don8 from "../images/client_logos/don8.jpg"
import don9 from "../images/client_logos/don9.jpg"
import don10 from "../images/client_logos/don10.jpg"
import don11 from "../images/client_logos/don11.jpg"
import don12 from "../images/client_logos/don12.jpg"
import don13 from "../images/client_logos/don13.jpg"
import don14 from "../images/client_logos/don14.jpg"
import don15 from "../images/client_logos/don15.jpg"
import don16 from "../images/client_logos/don16.jpg"
import don17 from "../images/client_logos/don17.jpg"
import don18 from "../images/client_logos/don18.jpg"
import don19 from "../images/client_logos/don19.jpg"
import don20 from "../images/client_logos/don20.jpg"
import don21 from "../images/client_logos/don21.jpg"
import don22 from "../images/client_logos/don22.jpg"
import don23 from "../images/client_logos/don23.jpg"
import don24 from "../images/client_logos/don24.jpg"
import don25 from "../images/client_logos/don25.jpg"
import don26 from "../images/client_logos/don26.jpg"
import don27 from "../images/client_logos/don27.jpg"
import don28 from "../images/client_logos/don28.jpg"
import don29 from "../images/client_logos/don29.jpg"
import don30 from "../images/client_logos/don30.jpg"
import don31 from "../images/client_logos/don31.jpg"
import don32 from "../images/client_logos/don32.jpg"
import don33 from "../images/client_logos/don33.jpg"
import don34 from "../images/client_logos/don34.jpg"
import don35 from "../images/client_logos/don35.jpg"
import don36 from "../images/client_logos/don36.jpg"
import don37 from "../images/client_logos/don37.jpg"
import don38 from "../images/client_logos/don38.jpg"
import don39 from "../images/client_logos/don39.jpg"
import don40 from "../images/client_logos/don40.jpg"
import don41 from "../images/client_logos/don41.jpg"
import don42 from "../images/client_logos/don42.jpg"
import don43 from "../images/client_logos/don43.jpg"
import don44 from "../images/client_logos/don44.jpg"
import don45 from "../images/client_logos/don45.jpg"
import don46 from "../images/client_logos/don46.jpg"
import don47 from "../images/client_logos/don47.jpg"
import don48 from "../images/client_logos/don48.jpg"
import don49 from "../images/client_logos/don49.jpg"

import str1 from "../images/client_logos/str1.jpg"
import str2 from "../images/client_logos/str2.jpg"
import cdots1 from "../images/dots/media/1.png"
import cdots2 from "../images/dots/media/2.png"

const ourPartners = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  return (
    <Layout pageName="our partners">
      <SEO title="Our Partners" />
      <Container className="posrel text-center my-5" id="donors">
        <img
          src={cdots1}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
          className="color-patcher"
        />
        <img
          src={cdots2}
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="1000"
          className="color-patcher"
        />
        <p className="wn-title">Our Partners</p>
        <p className="fi-txt max-60 max-sm-100 mx-auto mb-4">
          Teach For India gratefully acknowledges the support of the following
          individuals, foundations, and corporations who have championed our
          efforts.
        </p>
        <p className="mb-5 pb-5">
          <a
            href="/support-us"
            className="butn butn-main mr-2 donate-butn butn-sm-sp"
          >
            Support Us
          </a>
          <a href="/contact" className="butn butn-main donate-butn butn-sm-sp">
            Contact Us
          </a>
        </p>
      </Container>
      <Container className="my-5">
        <p className="wn-title text-center">Our Donors</p>
        <div className="col-md-10 offset-md-1 my-3 my-md-5 max-sm-60 mx-auto pb-5 pb-md-0">
          <div className="client-logo-grid">
            <div className="client-logo-cont">
              <img src={don1} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don2} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don3} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don4} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don5} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don6} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don7} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don8} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don9} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don10} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don11} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don12} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don13} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don14} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don15} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don16} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don17} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don18} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don19} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don20} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don21} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don22} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don23} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don24} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don25} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don26} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don27} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don28} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don29} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don30} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don31} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don32} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don33} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don34} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don35} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don36} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don37} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don38} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don39} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don40} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don41} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don42} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don43} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don44} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don45} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don46} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don47} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don48} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={don49} className="cleint-logo w-100" />
            </div>
          </div>
        </div>
      </Container>
      <Container className="text-center" id="strategic-partners">
        <img src={dnicon2} className="dn-icon2 mx-auto" />
      </Container>

      <Container className="my-5 text-center">
        <p className="wn-title text-center">Strategic Partners</p>
        <div className="col-md-10 offset-md-1 my-3 my-md-5 max-sm-60 mx-auto pb-5 pb-md-0">
          <div className="client-logo-grid">
            <div className="client-logo-cont">
              <img src={str1} className="cleint-logo w-100" />
            </div>
            <div className="client-logo-cont">
              <img src={str2} className="cleint-logo w-100" />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ourPartners
